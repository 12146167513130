<template>
  <page-structure :loading="loading">
    <div class="row picture">
      <div
        v-if="picture"
        class="col-md-12 animate-box fadeInUp animated blog-single"
        data-animate-effect="fadeInUp"
      >
        <div class="content picture-item">
          <h2 class="mt-20 text-center">{{ picture.title }}</h2>
          <div class="img img-container">
            <img v-gallery class="img-fluid" :src="picture.image.url" alt="" />
            <!-- <img v-img class="img-fluid" :src="picture.image.url" alt="" /> -->
            <div :class="['status', { available: picture.available }]">
              {{ picture.available ? "Available" : "Temporarily unavailable" }}
            </div>
          </div>
          <span class="date">{{ new Date(picture.date).getFullYear() }}</span>
          <div
            class="parammeter"
            v-for="(value, name) in picture.params"
            :key="name"
          >
            <div class="title">{{ name | capitalize }}</div>
            <p class="value">{{ value }}</p>
          </div>
        </div>
        <!-- STRAT: Details form -->
        <div class="col-sm-6">
          <form @submit.prevent="submitHandler">
            <h6>Order/get information</h6>

            <div
              class="form-component"
              :class="{
                invalid: $v.contact.$dirty && !$v.contact.required,
              }"
            >
              <div class="input">
                <input
                  type="text"
                  v-model="contact"
                  placeholder="Email / Phone *"
                />
              </div>
              <div class="button">
                <button type="submit">
                  <base-icon name="arrow-next" />
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="tools">
          <!-- <button
            class="btn-back"
            @click.prevent="
              $router.push(`/portfolio?page=${$route.params.page}`)
            "
          >
            <base-icon name="arrow-back" />
          </button> -->
          <!-- <a class="next-link" :href="`/portfolio?page=${$route.params.page}`"
            >Back</a
          > -->
        </div>
      </div>
    </div>
  </page-structure>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  name: "picturePage",
  data: () => {
    return {
      loading: true,
      contact: "",
      picture: null,
    };
  },
  validations: {
    contact: { required },
  },
  methods: {
    async submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
      await this.$store.dispatch("requareInfo", {
        from_contact: this.contact,
        message: `Interested in picture: ${window.location.href}`,
      });
      this.contact = "";
    },
  },
  async mounted() {
    this.$emit("setsocial", true);
    try {
      const id = this.$route.params.id;
      // this.picture = pictures.filter((picture) => {
      //   return picture.id == id;
      // })[0];
      const picture = await this.$store.dispatch("fetchPictureById", id);
      this.picture = picture;
      this.loading = false;
    } catch (err) {
      console.error(err);
    }
  },
  components: {},
  metaInfo: {
    title: "Picture",
    titleTemplate: "%s - moldoVAN",
    htmlAttrs: {
      lang: "en",
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/picturePage";
</style>
